/* -------------------------------- 

Slider

-------------------------------- */
.cd-hero-slider {
  position: relative;
  height: auto;
  overflow: hidden;
  margin: 0;

  li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    overflow: hidden;
    margin: 0;

    @include mq(tablet, desktop) {
      padding: 30px 0;
    }
    @include mq($from: tablet) {
      margin-bottom: 20px;
    }
    @include mq($from: desktop) {
      margin-bottom: 60px;
    }
    @include mq($from: wide) {
      margin-bottom: 80px;
    }

    &.selected {
      /* this is the visible slide */
      position: relative;
      transform: translateX(0);
    }

    &.move-left {
      /* slide hidden on the left */
      transform: translateX(-100%);
    }

    &.is-moving,
    &.selected {
      /* the is-moving class is assigned to the slide which is moving outside the viewport */
      transition: transform 0.8s;
    }
  }
}

/* -------------------------------- 

Single slide style

-------------------------------- */
.cd-hero-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.cd-text-container {
  padding-top: 50px;

  @include mq($from: tablet) {
    padding-top: 0;
  }
  @include mq($from: desktop) {
    padding-top: 70px;
  }
  @include mq($from: wide) {
    padding-top: 0px;
  }
}

.cd-img-container {

  @include mq($from: desktop) {
    display: block;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

/* -------------------------------- 

Single slide animation

-------------------------------- */
@include mq($from: tablet) {
  .cd-hero-slider {

    .cd-hero-container {
      opacity: 0;
      transform: translateX(60px);

      @include mq($from: desktop) {
        transform: translateX(80px);
      }
      @include mq($from: wide) {
        transform: translateX(120px);
      }
    }
    .move-left .cd-hero-container {
      transform: translateX(-60px);

      @include mq($from: desktop) {
        transform: translateX(-80px);
      }
      @include mq($from: wide) {
        transform: translateX(-120px);
      }
    }

    .selected .cd-hero-container {
      /* this is the visible slide */
      opacity: 1;
      transform: translateX(0);
    }
    .is-moving .cd-hero-container {
      /* this is the slide moving outside the viewport 
      wait for the end of the transition on the <li> parent before set opacity to 0 and translate to 40px/-40px */
      transition: opacity 0s 0.6s, transform 0s 0.6s;
    }
  }
  .cd-hero-slider li.selected.from-left .cd-hero-container:nth-of-type(2),
  .cd-hero-slider li.selected.from-right .cd-hero-container:first-of-type {
    /* this is the selected slide - different animation if it's entering from left or right */
    transition: opacity 0.6s 0.2s, transform 0.6s 0.2s;
  }
  .cd-hero-slider li.selected.from-left .cd-hero-container:first-of-type,
  .cd-hero-slider li.selected.from-right .cd-hero-container:nth-of-type(2) {
    /* this is the selected slide - different animation if it's entering from left or right */

    transition: opacity 0.6s 0.4s, transform 0.6s 0.4s;
  }
}
/* -------------------------------- 

Slider navigation

-------------------------------- */
.cd-slider-nav {
  height: auto;
  width: auto;
  bottom: 0;
  z-index: 20;
  text-align: center;

  @include mq($from: tablet) {
    position: relative;
    display: inline-block;
    left: 30px;
    bottom: 81px;
  }
  @include mq($from: desktop) {
    position: relative;
    display: inline-block;
    left: 44px;
    bottom: 100px;
  }
  @include mq($from: wide) {
    left: 56px;
    bottom: 148px;
  }

  nav {
    display: inline-block;
    position: relative;
  }

  ul {

    &:after {
      clear: both;
      content: "";
      display: table;
    }

    li {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-bottom: 0;
      float: left;

      @include mq($from: tablet) {
        width: 24px;
        height: 24px;
      }
      @include mq($from: desktop) {
        width: 20px;
        height: 20px;
      }

      &:last-of-type {
        margin: 0;
      }

      &.selected a {
        background-color: $color-2;

        .no-touch & {
          &:hover {
            background-color: $color-2-darken;
          }
        }
      }
    }
  }
}

.cd-slider-nav a {
  display: block;
  position: relative;
  transition: background-color 0.2s;
  background-color: $color-text-light;
  background-color: lighten($color-text-light, 10%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 11px;

  .no-touch & {
    &:hover {
      background-color: $color-2-darken;
    }
  }
}

/* -------------------------------- 

Javascript disabled

-------------------------------- */
.no-js .cd-hero-slider li {
  display: none;
}
.no-js .cd-hero-slider li.selected {
  display: block;
}

.no-js .cd-slider-nav {
  display: none;
}
