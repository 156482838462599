///// OPEN SANS /////
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic);

///// COCO GOTHIC /////
/*** Regular ***/
@font-face {
    font-family: 'coco_gothicregular';
    src: url('../fonts/coco_gothic_regular-trial-webfont.eot');
    src: url('../fonts/coco_gothic_regular-trial-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/coco_gothic_regular-trial-webfont.woff2') format('woff2'),
         url('../fonts/coco_gothic_regular-trial-webfont.woff') format('woff'),
         url('../fonts/coco_gothic_regular-trial-webfont.ttf') format('truetype'),
         url('../fonts/coco_gothic_regular-trial-webfont.svg#coco_gothicregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*** Light ***/
@font-face {
    font-family: 'coco_gothiclight';
    src: url('../fonts/coco_gothic_light-trial-webfont.eot');
    src: url('../fonts/coco_gothic_light-trial-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/coco_gothic_light-trial-webfont.woff2') format('woff2'),
         url('../fonts/coco_gothic_light-trial-webfont.woff') format('woff'),
         url('../fonts/coco_gothic_light-trial-webfont.ttf') format('truetype'),
         url('../fonts/coco_gothic_light-trial-webfont.svg#coco_gothiclight') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*** Bold ***/
@font-face {
    font-family: 'coco_gothicbold';
    src: url('../fonts/coco_gothic_bold-trial-webfont.eot');
    src: url('../fonts/coco_gothic_bold-trial-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/coco_gothic_bold-trial-webfont.woff2') format('woff2'),
         url('../fonts/coco_gothic_bold-trial-webfont.woff') format('woff'),
         url('../fonts/coco_gothic_bold-trial-webfont.ttf') format('truetype'),
         url('../fonts/coco_gothic_bold-trial-webfont.svg#coco_gothicbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

///// MOON LIGHT (for numbers) /////
@font-face {
    font-family: 'moonlight';
    src: url('../fonts/moon_light-webfont.eot');
    src: url('../fonts/moon_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/moon_light-webfont.woff2') format('woff2'),
         url('../fonts/moon_light-webfont.woff') format('woff'),
         url('../fonts/moon_light-webfont.ttf') format('truetype'),
         url('../fonts/moon_light-webfont.svg#moonlight') format('svg');
    font-weight: normal;
    font-style: normal;
}


///// ICON FONT /////
@font-face {
	font-family: 'dti';
	src:url('../fonts/dti.eot?-9cosbq');
	src:url('../fonts/dti.eot?#iefix-9cosbq') format('embedded-opentype'),
		url('../fonts/dti.ttf?-9cosbq') format('truetype'),
		url('../fonts/dti.woff?-9cosbq') format('woff'),
		url('../fonts/dti.svg?-9cosbq#dti') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'dti';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e607";
}
.icon-youtube:before {
    content: "\e606";
}
.icon-chevron-small-right:before {
    content: "\e605";
}
.icon-plus:before {
    content: "\e604";
}
.icon-arrow-right:before {
    content: "\e603";
}
.icon-instagram:before {
	content: url("../images/instagram.svg");
}
.icon-social-linkedin:before {
	content: "\e601";
}
.icon-social-facebook:before {
	content: "\e602";
}