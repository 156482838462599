html { font-size: 62.5%; }


// FONTS
$font-base:  'coco_gothicregular', sans-serif;
$font-light: 'coco_gothiclight', sans-serif;
$font-bold:  'coco_gothicbold', sans-serif;
$font-number: 'Open Sans', sans-serif;
$font-number-2: 'moonlight', sans-serif;

$image-path: '/images/';


// COLORS
$color-1:        #2d3843; //anthracite
$color-1-lighten: #374552; // anthracite clair
$color-2:        #bde771; //anis
$color-2-darken: #a6d74e; //anis darken
$color-3:        #f2f3f3; //gris clair
$color-warning:  #FF4E50; //rouge

$color-advisor:       #c8c8c8;
$color-communication: #89c7ed;
$color-international: #7c91a6;

$white: #fff;
$black: #000;

$color-bg:     $white;
$color-bg-alt: $color-3;

$color-text:            $black;
$color-text-light:      #5c5c5c;
$color-text-ultralight: #cecece;

$color-link: $color-1;
$color-link-hover: lighten($color-1, 10%);

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;


// GRID GUTTERS
$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 30px / 2;
$grid-gutter-wide:    30px / 2;