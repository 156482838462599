html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
	height: 100%;
}

body {
	background-color: $color-bg;
	height: 100%;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

////// EDITOR TEXT //////
.editor-text {
	@include clearfix;
	color: $color-text;

	p {
		font-family: $font-number;
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline;
		border-bottom: 1px solid $color-2;

		transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: $color-link-hover;
				border-color: $color-2-darken;
			}
		}
	}

	h1,
	h2,
	h3 {
		font-family: $font-light;
		margin-bottom: 1.5em;
	}

	h1 {
		text-transform: uppercase;
		@include font-size(20);
	}

	h2 {
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
	}

	ul {
		@include clearfix;

		@include mq($from: tablet) {
			margin: 2.4em 0;
		}

		li {
			position: relative;

			@include mq($from: tablet) {
				padding-left: 28px;
			}

			&:before {
				display: block;
				content: "";
				width: 20px;
				height: 1px;
				background-color: $color-2-darken;
				position: absolute;
				left: -28px;
				top: 12px;

				@include mq($from: tablet) {
					left: 0;
				}
			}
		}
	}

	

	blockquote {
		margin: 0 !important;
	}

	img.logo-company {
		margin-bottom: 0;
		width: 120px;
		height: auto;
	}

	img.aligngauche,
	img.aligncentre,
	img.aligndroite {
		max-width: 100%;
		height: auto;
	}

	img.aligngauche {
		float: left;
		margin: 1em 1em 1em 0;
	}

	img.aligndroite {
		float: right;
		margin: 1em 0 1em 1em;
	}
}

.single-files {
	display: inline-block;
	@include clearfix;
	margin-top: 20px;
	padding: 20px;
	background-color: $color-3;
	border-top: 1px solid $color-3;

	li {
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			display: inline-block;
			line-height: 24px;
			padding: 0 10px 0 0;

			background-color: rgba($color-1, 0);
			border-radius: 14px;

			transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

			&:before {
				@include icon();
				display: inline-block;
				width: 24px;
				height: 24px;
				margin-right: 5px;
				content: "\e603";
				background-color: $color-2;

				text-align: center;
				line-height: 24px;
				border-radius: 50%;
				color: #fff;
				text-shadow: 1px 0 0 rgba(#000, 0.2);

				transform: rotate(90deg);
				transition: background-color 0.2s ease-in-out;

			}

			.no-touch &:hover,
			.no-touch &:focus,
			.touch &:active {
				background-color: $color-1;
				color: #fff;
				&:before {
					background-color: $color-1;
				}
			}
		}

		
	}
}

.font-num {
	font-family: arial !important;
}

////// STATIC //////
p,
ul {
	font-family: $font-base;
	font-family: $font-number;
	color: $color-text-light;
	line-height: 1.5em;
	margin: 0 0 1.2em 0;

	@include mq($from: wide) {
		margin: 0 0 1.5em 0;
	}
}

ul {
	li {
		margin: 0 0 1em 0;
	}
}

ul.main-list {
	@include clearfix;

	@include mq($from: tablet) {
		margin: 2.4em 0;
	}

	> li {
		position: relative;

		@include mq($from: tablet) {
			padding-left: 28px;
		}

		&:before {
			display: block;
			content: "";
			width: 20px;
			height: 1px;
			background-color: $color-2-darken;
			position: absolute;
			left: -28px;
			top: 12px;

			@include mq($from: tablet) {
				left: 0;
			}
		}
	}
}

a {
	color: $color-link;

	transition: color 0.2s ease-in-out;

	.no-touch & {
		&:hover, &:focus {
			color: $color-link-hover;
		}
	}

	&.default-link {
		display: inline;
		border-bottom: 1px solid $color-2;

		transition: border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				border-color: $color-2-darken;
			}
		}
	}
}

a.link-more {
	position: relative;
	display: inline-block;
	margin: 1em 0 0 0;

	font-family: arial;
	color: $color-2;
	@include font-size(12);
	text-transform: uppercase;

	@include mq($from: wide) {
		margin: 1.4em 0 0 0;
	}

	&:before {
		display: block;
		content: "";
		height: 1px;
		width: 0;
		background-color: $color-2;
		background-color: lighten($color-2, 5%);
		position: absolute;
		bottom: 4px;

		transition: width 0.3s ease-in-out;
	}

	&:after {
		font-family: 'dti';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

	    content: "\e603";

	    @include font-size(11);
	    margin-left: 5px;

		transition: margin 0.3s ease-in-out;
	}

	.no-touch & {
		&:hover {
			color: lighten($color-2, 5%);

			&:before {
				width: 20%;
			}

			&:after {
				margin-left: 10px;
			}
		}
	}

	&.link-more--darken {
		color: $color-2-darken;

		.no-touch & {
			&:hover {
				color: lighten($color-2-darken, 5%);
			}
		}
	}
}

a.link-back {
	display: inline-block;
	padding: 4px 10px 5px 10px;
	border: 1px solid $color-2;

	font-family: $font-light;
	color: $color-2-darken;
	margin: 2.5em 0 0 0;
}

sup {
	font-size: 0.6em;
}

.asterisk {
	color: lighten($color-text-light, 20%);
	font-size: 0.8em;

	a {
		color: lighten($color-text-light, 10%);
	}
}

.strong {
	font-weight: 600;
}

.align-right {text-align: right}
.align-left  {text-align: left}

.number,
.mailto {
	font-family: $font-number !important;
}

.number {
	font-weight: 100;
}

.number-2 {
	display: inline-block !important;
	font-family: $font-number-2 !important;
	letter-spacing: 0.05em;
}

.dash {
	position: relative;

	&:before {
		content: "";
		display: block;
		height: 1px;
		width: 20px;
		background-color: $color-2;
		position: absolute;
		top: 1em;
		left: -28px;

		@include mq($from: tablet) {
			width: 30px;
			left: -40px;
		}
		@include mq($from: desktop) {
			width: 40px;
			left: -54px;
		}
		@include mq($from: wide) {
			width: 48px;
			left: -66px;
		}
	}

	&.dash-darken {

		&:before {
			background-color: $color-2-darken;
		}
	}
}

.inner {
	@include mq($from: desktop) {
		margin-left: 54px !important;
	}
	@include mq($from: wide) {
		margin-left: 66px !important;
	}
}

.uppercase {
	text-transform: uppercase;
}


/* 
########   ####  ########   ##         ########   
   ##       ##      ##      ##         ##         
   ##       ##      ##      ##         ##         
   ##       ##      ##      ##         ######     
   ##       ##      ##      ##         ##         
   ##       ##      ##      ##         ##         
   ##      ####     ##      ########   ########   
*/
.title-lev1,
.title-lev2,
.title-lev3 {
	display: inline-block;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}

	&.title-light {
		color: $white;
	}
}

.title-lev1 {
	font-family: $font-light;
	@include font-size(30);
	line-height: 1.2em;
	margin: 0 0 1em 0;
	z-index: 2;

	@include mq($from: desktop) {
		margin: 0 0 1.2em 0;
	}
	@include mq($from: wide) {
		@include font-size(38);
		margin: 0 0 1.4em 0;
	}

	&:before {
		top: 0.6em;
	}

	span {
		// font-family: $font-bold;
		font-family: $font-base;
		@include font-size(32);

		@include mq($from: wide) {
			@include font-size(40);
		}
	}

	html[lang="de"] .reference &:first-of-type{
		@include mq($from: tablet) { @include font-size(22); }
	}

}

.title-lev2 {
	font-family: $font-base;
	@include font-size(18);
	color: $color-text;
	line-height: 1.2em;
	margin: 0 0 0.8em 0;

	@include mq($from: wide) {
		@include font-size(20);
	}

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}

	&:before {
		top: 0.6em;
	}
}

.title-lev3 {
	font-family: $font-light;
	@include font-size(24);
	color: $black;
	line-height: 1.2em;
	margin: 0 0 1em 0;
	padding-left: 18px;
	top: 60px;

	@include mq($from: tablet) {
		@include font-size(26);
		padding-left: 22px;
		top: 120px;
	}
	@include mq($from: desktop) {
		@include font-size(22);
		top: 55px;
	}
	@include mq($from: wide) {
		@include font-size(28);
		top: 60px;
	}

	.produit-gamme & {
		top: 30px;
	}

	&:before {
		background-color: $black;
		top: 0.6em;
		left: -10px;

		@include mq($from: tablet) {
			left: -15px;
		}
		@include mq($from: desktop) {
			width: 28px;
			left: -14px;
		}
		@include mq($from: wide) {
			width: 32px;
			left: -16px;
		}
	}
}

.title-half {
	@include mq($from: desktop) {
		width: 45%;
	}
}

.title-section {
	font-family: $font-light;
	color: $color-text-ultralight;
	@include font-size(18);
	margin: 0 0 1em 0;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}

	@include mq($from: desktop) {
		@include font-size(20);
		margin-top: 10px;
	}
	@include mq($from: wide) {
		@include font-size(25);
	}

	&.title-section--right {
		@include mq($from: desktop) {
			float: right;
			text-align: right;
		}
	}
	&.title-section--left {
		@include mq($from: desktop) {
			float: left;
		}
	}
	&.title-section--dark {
		color: rgba($color-text-ultralight, 0.3);
	}
	&.title-section--fullwidth {
		float: none;
	}
}

.catchphrase {
	font-family: $font-light;
	@include font-size(20);
	color: $color-1;
	line-height: 1.4em;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}

	@include mq($from: tablet) {
		width: 100%;
		@include font-size(22);
	}
	@include mq($from: desktop) {
		@include font-size(28);
		width: 80%;
	}
	@include mq($from: wide) {
		@include font-size(30);
		width: 70%;
	}

	&:before {
		top: 0.7em;
	}
}

.catchphrase--fullwidth {
	@include mq($from: tablet) {
		width: 80%;
	}
	@include mq($from: desktop) {
		width: 75%;
	}
	@include mq($from: wide) {
		width: 65%;
	}
}


/* 
##            ###     ##    ##    #######   ##     ##  ########   
##           ## ##     ##  ##    ##     ##  ##     ##     ##      
##          ##   ##     ####     ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
##         #########     ##      ##     ##  ##     ##     ##      
##         ##     ##     ##      ##     ##  ##     ##     ##      
########   ##     ##     ##       #######    #######      ##      
*/
.fromtablet {
	@include mq($until: tablet) {
		display: none;
	}
}
.fromdesktop {
	@include mq($until: desktop) {
		display: none;
	}
}
.tablettodesktop {
	@include mq($until: tablet) {
		display: none;
	}
	@include mq($from: desktop) {
		display: none;
	}
}

.wrapper {
	@include clearfix;
	position: relative;
	width: 100%;
	height: auto;
	padding: 20px;
	// z-index: 1;

	@include mq($from: tablet) {
		width: 700px;
		margin: 0 auto;
		padding: 0;
	}
	@include mq($from: desktop) {
		width: 960px;
	}
	@include mq($from: wide) {
		width: 1200px;
	}

	&.wrapper--mobile {
		@include mq($until: tablet) {
			max-width: 460px;
			margin: 0 auto;
		}
	}

	&.wrapper--header {
		// z-index: initial;

		@include mq($from: desktop) {
			height: 85px;
		}
	}

	&.wrapper--content {

		@include mq($from: tablet) {
			padding: 40px 0;
		}
		@include mq($from: desktop) {
			padding: 60px 0;
		}
		@include mq($from: wide) {
			padding: 80px 0;
		}
	}
}

.section-dark {
	background-color: $color-1;

	&.section-dark-light {
		background-color: $color-1-lighten;

		.video {
			width: 100%;
			// height: 450px;

			video {
				width: 100%;
			}
		}
	}
}
.section-light {
	background-color: $color-3;
}

.hidden-mobile {

	@include mq($until: tablet) {
		display: none;
	}
}

.two-columns {

	@include mq($from: desktop) {
		width: 100%;
		column-count: 2;
		column-gap: 40px;
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
////// BACKGROUND //////
.header {
	position: relative;
	height: 280px;
	
	.touch & {
		height: 225px;
	}

	background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/gradients.png'),
		  url('#{$image-path}banner/banner-eclairage.jpg');
	.produit & {
		background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/gradients.png'),
		  url('#{$image-path}banner/banner-produit.jpg');
	}
	.eclairage & {
		background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/gradients.png'),
		  url('#{$image-path}banner/banner-eclairage.jpg');
	}
	.solaire & {
		background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/gradients.png'),
		  url('#{$image-path}banner/banner-solaire.jpg');
	}
	.photo & {
		background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/gradients.png'),
		  url('#{$image-path}banner/banner-photo.jpg');
	}
	background-size:
	  36px 36px,
	  cover,
	  cover;
	background-position:
	  0 18px,
	  top left,
	  top center;
	background-repeat:
	  repeat,
	  no-repeat,
	  no-repeat;

	.home & {
		height: 100%;
		background-image: 
		  url('#{$image-path}banner/pattern.png'),
		  url('#{$image-path}banner/banner-home.jpg');
		background-size:
		  36px 36px,
		  cover;
		background-position:
		  0 18px,
		  top center;
		background-repeat:
		  repeat,
		  no-repeat;
	}

	@include mq($from: tablet) {
		.touch & {
			height: 245px;
		}
	}
	@include mq($from: desktop) {
		height: 420px;

		.touch & {
			height: 300px;
		}

		.home & {
			height: 480px;
		}
	}
	@include mq($from: wide) {
		height: 450px;

		.home & {
			height: 600px;
		}
	}

	&:before {
		content: '';
		height: 1px;
		width: 100%;
		background-color: rgba(255,255,255, .35);
		position: absolute;
		bottom: 54px;

		@include mq($from: desktop) {
			bottom: 89px;
		}
		@include mq($from: wide) {
			bottom: 112px;
		}

		.home & {
			bottom: 170px;

			@include mq($from: tablet) {
				bottom: 232px;
			}
			@include mq($from: desktop) {
				bottom: 222px;
			}
			@include mq($from: wide) {
				bottom: 293px;
			}
		}
	}

	&:after {

		@include mq($from: tablet) {
			content: '';
			width: 1px;
			background-color: rgba(255,255,255, .35);
			position: absolute;
			bottom: 0;
			height: 54px;
			left: 200px;
		}
		@include mq($from: desktop) {
			left: 308px;
			height: 89px;
		}
		@include mq($from: wide) {
			left: 480px;
			height: 112px;
		}

		.home & {
			height: 232px;

			@include mq($from: desktop) {
				height: 222px;
			}
			@include mq($from: wide) {
				height: 293px;
				left: 480px
			}
		}
	}
}

////// TOP BAR //////
.topbar {
	position: relative;
	width: 100%;
	padding-bottom: 1px;
	border-bottom: 1px solid $white;
	border-color: rgba($white, 0.35);
	z-index: 10;

	@include mq($from: desktop) {
		height: 36px;
	}

	.wrapper {
		@include mq($until: tablet) {
			padding: 0;
		}
		@include mq(tablet, desktop) {
			// padding: 0 14px 0 0;
			width: 100%;
		}
	}
}

.topbar--content {
	position: relative;
	float: right;
	text-align: right;
	@include mq($until: tablet) {
		width:100%;		
	}
	@include mq($from: tablet) {
		height:50px;		
	}
}

.topbar--social {
	@include mq($until: tablet) {
		display: none;
	}

	float: left;
	margin:0;

	li {
		display: inline-block;
		margin: 0 5px 0 0;

		@keyframes scale {
			25% {
				-webkit-transform: scale(1.2);
				transform: scale(1.2);
			}

			75% {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}

		a {
			line-height: 2.35em;
			@include font-size(20);
			color: $white;
			color: rgba($white, 0.9);

			transition: transform 0.2s ease-in-out;

			@include mq($from: desktop) {
				line-height: 1.6em;
			}

			.no-touch & {
				&:hover {
					color: $white;

					animation-name: scale;
					animation-duration: 0.3s;
					animation-timing-function: linear;
					animation-iteration-count: 1;
				}
			}
		}
	}
}

.js .nav-collapse {
	clip: rect(0 0 0 0);
	max-height: 0;
	position: absolute;
	display: block;
	overflow: hidden;
	zoom: 1;
}

.nav-collapse.opened {
	max-height: 9999px;
}

.nav-toggle {
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	height: 50px;
	line-height: 56px;
	padding:0 25px 0 15px;
	background-color: rgba($color-1, 0.25);
	display: inline-block;
	font-family: $font-bold;
	@include font-size(11);
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 0.05em;
	color: $white;
	position: relative;
	.no-touch &:hover, .no-touch &:focus, .touch &:active {
		color:$white;
		background-color: rgba($white, 0.15);
	}

	[class^="icon-"]{
		@include font-size(20);
		position: absolute;
		top:50%;
		right:0;
		margin:-10px 0 0 0;
		transform: rotate(90deg);
	}

}

@include mq($from: tablet) {
	.js .nav-collapse {
		position: relative;
	}
	.js .nav-collapse.closed {
		max-height: none;
	}
	.nav-toggle {
		display: none;
	}
}

.topbar--lang {
	overflow: hidden;

	@include mq($from: desktop) {
		margin: 0 0 0 15px;
		float: left;
		height: 35px;
		margin: 0 0 0 20px;
	}

	li {
		display: block;
		float: left;
		margin-left: 1px;
		padding: 0;

		a {
			display: block;
			position: relative;
			vertical-align: top;
			margin: 0;
			width: 50px;
			height: 50px;
			line-height: 56px;
			overflow: hidden;
			background-color: rgba($color-1, 0.25);

			font-family: $font-bold;
			@include font-size(11);
			text-transform: uppercase;
			text-align: center;
			letter-spacing: 0.05em;
			color: $white;

			transition: background-color 0.25s ease-in-out;

			@include mq($from: desktop) {
				@include font-size(10);
				width: 36px;
				height: 36px;
				line-height: 40px;
			}

			span {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 1;
				color: rgba($white, 0.9);
				border: none;

				transition: top 0.25s ease-in-out, opacity 0.2s ease-in-out;
			}

			&:after {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 50px;
				left: 0;

				content: attr(data-lang);
				color: $white;

				transition: top 0.25s ease-in-out;

				@include mq($from: desktop) {
					top: 36px;
				}
			}

			.no-touch & {
				&:hover {
					color: $white;
					background-color: rgba($white, 0.15);

					span {
						top: -50px;
						opacity: 0;

						@include mq($from: desktop) {
							top: -36px;
						}
					}

					&:after {
						top: 0;
					}
				}
			}

			&.current{
				background-color: rgba($white, 0.15);

				span {
					top: -50px;
					opacity: 0;

					@include mq($from: desktop) {
						top: -36px;
					}
				}

				&:after {
					top: 0;
				}
			}
		}
	}

	@include mq($until: tablet) {
		width:100%;
		li{
			margin:0;
			width:percentage(1/7);
			a{
				width:100%;
			}
		}

	}

}

////// LOGO //////
.logo {
	display: block;
	text-indent: -9999px;
	width: 160px;
	height: 160px;
	background: transparent url('#{$image-path}logo.png') 0 0 no-repeat;
	background-size: 160px 160px;
	margin: -40px auto 0 auto;

	@include retina {
		background-image: url('#{$image-path}logo@2x.png');
		background-size: 160px 160px;
	}

	@include mq($from: tablet) {
		width: 180px;
		height: 180px;
		background-size: 180px 180px;
		margin: -20px auto 0 auto;

		@include retina {
			background-size: 180px 180px;
		}
	}
	@include mq($from: desktop) {
		width: 220px;
		height: 220px;
		background-size: 220px 220px;
		margin: -70px 0 0 0;

		@include retina {
			background-size: 220px 220px;
		}
	}
	@include mq($from: wide) {
		width: 240px;
		height: 240px;
		background-size: 240px 240px;
		margin: -76px 0 0 0;

		@include retina {
			background-size: 240px 240px;
		}
	}

	.home & {
		@include mq(mobile, tablet) {
			width: 200px;
			height: 200px;
			background-size: 200px 200px;
			margin: -20px auto 0 auto;

			@include retina {
				background-size: 200px 200px;
			}
		}
		@include mq(tablet, desktop) {
			width: 220px;
			height: 220px;
			background-size: 220px 220px;
			margin: 0 auto;

			@include retina {
				background-size: 220px 220px;
			}
		}
	}
}

////// TAGLINE //////
.wrapper--tagline {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
}

.tagline {
	width: auto;
	position: absolute;
	bottom: 20px;
	right: 0;
	text-align: right;
	padding-right: 26px;

	font-family: $font-light;
	@include font-size(28);
	text-transform: uppercase;
	color: $white;
	line-height: 1.2em;

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}

	@include mq($from: tablet) {
		@include font-size(30);
		bottom: 17px;
		padding-right: 34px;
	}
	@include mq($from: desktop) {
		@include font-size(44);
		bottom: 36px;
		padding-right: 47px;
	}
	@include mq($from: wide) {
		@include font-size(52);
		padding-right: 56px;
		bottom: 50px;
	}

	.home & {
		@include font-size(42);
		padding-right: 31px;

		@include mq($from: tablet) {
			@include font-size(62);
			padding-right: 38px;
			bottom: 10px;
		}
		@include mq($from: desktop) {
			@include font-size(54);
			padding-right: 46px;
			bottom: 30px;
		}
		@include mq($from: wide) {
			@include font-size(64);
			padding-right: 54px;
			bottom: 64px;
		}
	}

	&:after {
		display: block;
		content: "";
		height: 1px;
		width: 20px;
		background-color: $color-2;
		position: absolute;
		top: 15px;
		right: 0;

		@include mq($from: tablet) {
			width: 28px;
			top: 16px;
		}
		@include mq($from: desktop) {
			width: 40px;
			top: 24px;
			height: 2px;
		}
		@include mq($from: wide) {
			width: 48px;
			top: 29px;
		}

		.home & {
			height: 2px;
			top: 23px;
			width: 26px;

			@include mq($from: tablet) {
				width: 34px;
				top: 34px;
			}
			@include mq($from: desktop) {
				width: 40px;
				top: 29px;
			}
			@include mq($from: wide) {
				width: 48px;
				top: 35px;
			}
		}
	}
}

/* 
##    ##      ###     ##     ##  
###   ##     ## ##    ##     ##  
####  ##    ##   ##   ##     ##  
## ## ##   ##     ##  ##     ##  
##  ####   #########   ##   ##   
##   ###   ##     ##    ## ##    
##    ##   ##     ##     ###     
*/
////// MENU MOBILE //////
.menu-mobile {
	@include mq($from: desktop) {
		display: none;
	}
}

.burger {
	position: fixed;
	top: 0;
	left: 0;
	width: 50px;
	height: 49px;
	cursor: pointer;
	z-index: 110;
	
	background-color: rgba($color-1, 0.2);

	transition: opacity 0.25s ease;
	
	.no-touch & {
		&:hover {
	    	opacity: 0.8;
		}
	}
  
	&.active {
		.burger-top {
			transform: translateY(9px) translateX(0) rotate(45deg);
			background: $white;
		}

		.burger-middle {
			opacity: 0;
			background: $white;
		}
    
		.burger-bottom {
			transform: translateY(-9px) translateX(0) rotate(-45deg);
			background: $white;
		}
	}
}

.burger-element {
	background: $white;
	border: none;
	height: 4px;
	width: 26px;
	position: absolute;
	left: 50%;
	margin-left: -13px;
	transition: all .35s ease;
	cursor: pointer;

	&:first-of-type {top: 13px;} 
	&:nth-of-type(2) {top: 22px;} 
	&:nth-of-type(3) {top: 31px;}
}

.overlay-menu {
	position: fixed;
	background: $color-1;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.35s, visibility 0.35s, height 0.35s;
	overflow: hidden;
	z-index: 100;

	&.open {
		opacity: 0.95;
		visibility: visible;
		height: 100%;

		li {
			animation: fadeInRight .5s ease forwards;
			animation-delay: .2s;
			&:nth-of-type(2) {animation-delay: .25s;}
			&:nth-of-type(3) {animation-delay: .3s;}
			&:nth-of-type(4) {animation-delay: .35s;}
			&:nth-of-type(5) {animation-delay: .4s;}
			&:nth-of-type(6) {animation-delay: .45s;}
			&:nth-of-type(7) {animation-delay: .5s;}
			&:nth-of-type(8) {animation-delay: .55s;}
			&:nth-of-type(9) {animation-delay: .6s;}
		}
	}
}

nav.nav-mobile {
	position: relative;
	height: 90%;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;

	ul {
		padding: 10% 0 0 0;
		margin: 0 auto;
		display: inline-block;
		position: relative;
		height: 100%;

		li {
			margin: 0;
			display: block;
			height: 11%;
			height: calc(100% / 9);
			min-height: 20px;
			position: relative;
			opacity: 0;

			a {
				display: inline-block;
				position: relative;
				
				font-family: $font-bold;
				@include font-size(18);
				color: $white;
				text-transform: uppercase;

				transition: color 0.2s ease-in-out;

				.no-touch & {
					-webkit-font-smoothing: antialiased;
				}

				&:after {
					content: '';
					position: absolute;
					top: 6px;
					left: -32px;
					width: 0;
					height: 2px;
					background: $color-2;
					transition: .35s;
				}
        
				&:hover,
				&:focus,
				&:active,
				&.current {
					color: $color-2;

					&:after {
						width: 24px;
					}
				}
			}
		}
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

////// MENU DESKTOP //////
nav.nav-desktop {
	@include mq($until: desktop) {
		display: none;
	}

	position: absolute;
	right: 0;
	top: 0;
	height: 100%;

	> ul {
		text-align: right;
		height: 100%;

		> li {
			display: inline-block;
			height: 100%;
			margin: 0 0 0 10px;

			@include mq($from: wide) {
				margin: 0 0 0 15px;
			}

			> a {
				display: block;
				position: relative;
				height: 85px;
				line-height: 85px;
				
				font-family: $font-light;
				@include font-size(10);
				color: $white;
				text-transform: uppercase;

				@include mq($from: wide) {
					font-family: $font-base;
					@include font-size(11);

					.no-touch & {
						-webkit-font-smoothing: antialiased;
					}
				}

				&:after {
					content: '';
					position: absolute;
					height: 1px;
					width: 0;
					bottom: 32px;
					right: 0;
					background: $color-2;

					transition: width 0.2s ease-in-out;

					@include mq($from: wide) {
						bottom: 34px;
					}
				}
        
				.no-touch & {
					&:hover, &:focus {
						color: $white;

						&:after {
							width: 20px;

							@include mq($from: wide) {
								width: 24px;
							}
						}
					}
				}

				&.current {
					color: $white;

					&:after {
						width: 20px;

						@include mq($from: wide) {
							width: 24px;
						}
					}
				}
			}
		}
	}
}

.sticky-nav {

	@include mq($from: desktop) {
		width: 100%;
		position: fixed;
		top: 36px;
		left: 0;
		z-index: 25;
		background-size: 100% 0;
		transition:
			height 0.3s ease-in-out,
			top 0.3s ease-in-out,
			background-color 0.3s ease-in-out;

		.logo {
			transition:
				height 0.3s ease-in-out,
				width 0.3s ease-in-out,
				background-size 0.3s ease-in-out,
				margin 0.3s ease-in-out; 
		}

		.nav-desktop {
			transition: top 0.3s ease-in-out;
		}
	}
}

header.smaller .sticky-nav {
	@include mq($from: desktop) {
		top: 0;
		height: 85px;
		background-color: $color-1;
		background-color: rgba($color-1, 0.6);

		.logo {
			width: 118px;
			height: 118px;
			background-size: 118px 118px;
			margin: -18px 0 0 0;
		}

		// .nav-desktop {
		// 	top: 28px;
		// }
	}
}

/* 
 ######    ##     ##  ########   ##    ##      ###     ##     ##  
##    ##   ##     ##  ##     ##  ###   ##     ## ##    ##     ##  
##         ##     ##  ##     ##  ####  ##    ##   ##   ##     ##  
 ######    ##     ##  ########   ## ## ##   ##     ##  ##     ##  
      ##   ##     ##  ##     ##  ##  ####   #########   ##   ##   
##    ##   ##     ##  ##     ##  ##   ###   ##     ##    ## ##    
 ######     #######   ########   ##    ##   ##     ##     ###     
*/
.nav-desktop > ul > li {
	position: relative;
}

.subnav {
	display: none;
	text-align: left !important;

	@include mq($from: desktop) {
		display: block;
		width: auto;
		min-width: 830px;
		position: absolute;
		top: 100%;
		left: -60px;
		margin: 0;
		padding: 20px;
		background-color: $color-1;
		background-color: rgba($color-1, 0.8);
		text-align: left;

		visibility: hidden;
		opacity: 0;
		transition: left 0.3s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
	}

	ul {
		margin: 0;
		text-align: left !important;
	}

	li {
		width: auto;
		margin: 0;
		text-align: left;

		&.subnav-type--item {
			width: auto;
			display: inline-block;
			margin: 0 20px 20px 0;

			&:last-of-type {
				margin-right: 0;
				//border-left: 1px solid $color-text-ultralight;
				border-color: rgba($color-text-ultralight, 0.25);
				padding-left: 25px;
			}

			> a {
				color: $color-2;
				margin: 0 0 0.6em 0;
				position: relative;

				transition: color 0.2s ease-in-out;

				&:after {
					display: block;
					content: "";
					height: 1px;
					width: 0;
					bottom: 3px;
					background-color: $color-2-darken;
					background-color: rgba($color-2-darken, 0.8);
					position: absolute;

					transition: width 0.2s ease-in-out;
				}

				.no-touch & {
					&:hover {
						color: $color-2-darken;

						&:after {
							width: 20px;
						}
					}
				}

				span {
					border: none;
				}
			}
		}

		&.subnav-gamme--item {
			display: inline-block;
			margin: 20px 60px 0 0;
			vertical-align: top;

			&:last-of-type {
				margin-right: 0;
			}

			> a {
				text-transform: uppercase;
				@include font-size(13);
				color: $white;
				position: relative;
				margin-bottom: 10px;

				&:after {
					display: block;
					content: "";
					height: 1px;
					width: 0;
					bottom: 3px;
					background-color: $color-text-ultralight;
					position: absolute;

					transition: width 0.2s ease-in-out;
				}

				.no-touch & {
					&:hover {
						color: $white;

						&:after {
							width: 24px;
						}
					}
				}
			}
		}

		&.subnav-product--item {
			display: block;

			> a {
				transition: color 0.2s ease-in-out;
				position: relative;

				&:after {
					display: block;
					content: "";
					height: 1px;
					width: 0;
					background-color: $color-text-ultralight;
					position: absolute;

					transition: width 0.2s ease-in-out;
				}

				.no-touch & {
					&:hover {
						color: $white;

						&:after {
							width: 16px;
						}
					}
				}
			}
		}
	}

	.subnav-product--list {
		.subnav-lev1 {
			&:after {
				bottom: 0px;
			}
		}
	}

	a {
		display: inline-block;
		width: auto;
		font-family: $font-base;
		color: $color-text-ultralight;
		color: lighten($color-text-ultralight, 10%);

		.no-touch & {
			-webkit-font-smoothing: antialiased;
		}

		&.subnav-lev1 {
			color: $white;
			text-transform: uppercase;
			margin: 0 0 0.8em 0;

			&:before {
				background-color: rgba($color-2, 0.8);
				width: 24px;
				left: -32px;
				top: 7px;

				transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
			}

			&:after {
				display: block;
				position: relative;
				content: "";
				bottom: 4px;
				width: 0;
				height: 1px;
				background-color: $color-2;

				transition: width 0.2s ease-in-out;
			}

			.no-touch & {
				&:hover {
					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	> li:last-of-type a {
		margin: 0;
	}
}

.nav-desktop > ul > li:hover > .subnav {
	visibility: visible;
	left: -100px;
	opacity: 1;
}



/* 
   ###     ########   ####     ###     ##    ##   ########   
  ## ##    ##     ##   ##     ## ##    ###   ##   ##         
 ##   ##   ##     ##   ##    ##   ##   ####  ##   ##         
##     ##  ########    ##   ##     ##  ## ## ##   ######     
#########  ##   ##     ##   #########  ##  ####   ##         
##     ##  ##    ##    ##   ##     ##  ##   ###   ##         
##     ##  ##     ##  ####  ##     ##  ##    ##   ########   
*/
.breadcrumb {
	position: relative;
	z-index: 1;

	@include mq($from: tablet) {
		border-bottom: 1px solid $color-text-ultralight;
		border-color: rgba($color-text-ultralight, 0.5);
	}

	ul {
		height: auto;
		margin: 0;

		@include mq($from: tablet) {
			height: 35px;
			line-height: 35px;
		}
		@include mq($from: desktop) {
			height: 40px;
			line-height: 40px;
		}

		li {
			display: inline-block;
			position: relative;
			margin: 0;
			padding: 0 16px 0 0;

			&:after {
				display: inline-block;
				font-family: 'dti';
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				content: "\e605";
				color: $color-text-light;

				position: absolute;
				right: 0;
				top: 1px;

				@include mq($from: tablet) {
					top: 9px;
				}
				@include mq($from: desktop) {
					top: 11px;
				}
			}

			a,
			span {
				margin: 0;
				padding: 0;
				display: block;
				font-family: $font-base;
				@include font-size(12);
				color: $color-text-light;

				.no-touch & {
					-webkit-font-smoothing: antialiased;
				}
			}

			a {
				.no-touch & {
					&:hover {
						color: $color-2-darken;
					}
				}
			}

			&:last-of-type {
				&:after {
					display: none;
				}

				span {
					color: $color-2-darken;
					color: darken($color-2-darken, 10%);
				}
			}
		}
	}
}


/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/
////// PHOTOS INTRO //////
.cbp-so-scroller {
	overflow: hidden;

	@include mq($from: tablet) {
		margin-top: -80px;
	}
	@include mq($from: desktop) {
		margin-top: -100px;
	}
	@include mq($from: wide) {
		margin-top: -130px;
	}
}

.cbp-so-section {
	position: relative;
	height: 500px;

	@include mq($from: wide) {
		height: 600px;
	}
}

/* Clear floats of children */
.cbp-so-section:before,
.cbp-so-section:after {
	content: " ";
	display: table;
}

.cbp-so-section:after {
	clear: both;
}

/* Sides */
.cbp-so-side {
	position: absolute;
	top: 0;
	width: 70%;
	height: auto;
	margin: 0;
	transition: top 0.5s, opacity 0.5s;
}

.cbp-so-side-img1 {
	right: 5px;
	z-index: 1;

	@include mq($from: desktop) {
		top: 50px;
	}
	@include mq($from: wide) {
		right: 12px;
		top: 65px;
	}
}
.cbp-so-side-img2 {
	left: 0;
	z-index: 0;
}

/* Clear floats of children */
.cbp-so-side:before,
.cbp-so-side:after {
	content: " ";
	display: table;
}

.cbp-so-side:after {
	clear: both;
}

.cbp-so-side img {
	width: 100%;
	height: auto;
}

/* Initial state (hidden or anything else) */
.cbp-so-init .cbp-so-side {
	opacity: 0;
}

.cbp-so-init .cbp-so-side-img1 {
	top: 120px;
}

.cbp-so-init .cbp-so-side-img2 {
	top: 240px;
	transition: top 1s, opacity 1s;
}

/* Animated state */
.cbp-so-section.cbp-so-animate .cbp-so-side-img1,
.cbp-so-section.cbp-so-animate .cbp-so-side-img2 {
	opacity: 1;
}	
.cbp-so-section.cbp-so-animate .cbp-so-side-img1 {
	@include mq($from: tablet) {
		top: 0;
	}
}
.cbp-so-section.cbp-so-animate .cbp-so-side-img2 {
	@include mq($from: tablet) {
		top: 100px;
	}
	@include mq($from: desktop) {
		top: 140px;
	}
	@include mq($from: wide) {
		top: 160px;
	}
}
//////////////////////////

////// HERO SLIDER //////
.cd-hero {
	overflow: hidden;
	max-height: 460px;

	@include mq($from: tablet) {
		max-height: 405px;
	}
	@include mq($from: desktop) {
		max-height: 470px;
	}
	@include mq($from: wide) {
		max-height: 595px;
	}

	.title-section {
		position: absolute;
		top: 30px;
		left: 20px;
		z-index: 10;

		@include mq($from: tablet) {
			left: 0;
		}
		@include mq($from: desktop) {
			top: 60px;
		}
		@include mq($from: wide) {
			top: 80px;
		}
	}

	.title-lev1 {
		margin: 0 0 0.5em 28px;

		@include mq($until: tablet) {
			@include font-size(25);
		}
		@include mq($from: tablet) {
			margin: 0 0 0.75em 40px;
		}
		@include mq($from: desktop) {
			margin: 0 0 1em 54px;
		}
		@include mq($from: wide) {
			@include font-size(38);
			margin: 0 0 1.2em 66px;
		}
	}

	p, a.link-more {
		padding-left: 28px;

		@include mq($from: tablet) {
			padding-left: 40px;
		}
		@include mq($from: desktop) {
			padding-left: 54px;
		}
		@include mq($from: wide) {
			padding-left: 66px;
		}
	}

	 p {
	    font-family: $font-light;
	    color: $color-text-ultralight;
	    color: rgba($color-text-ultralight, 0.8);

	    @include mq($from: desktop) {
	    	width: 90%;
	    }
	    @include mq($from: wide) {
	    	width: 80%;
	    }
	  }
}
//////////////////////////

////// HERO SLIDER //////
.home--list-actu {
	clear: both;

	padding-top: 10px;

	> li {
		margin-bottom: 20px;

		&:last-of-type {
			margin: 0;
		}

		@include mq($from: tablet) {
			margin-bottom: 30px;
		}
		@include mq($from: desktop) {
			width: 50%;
			width: percentage(1/2);
			float: left;
			padding-right: 40px;
		}
		@include mq($from: wide) {
			padding-right: 60px;
		}

		.title-lev2 {
			@include mq($from: desktop) {
				width: 80%;
			}
			@include mq($from: wide) {
				width: 70%;
			}
		}

		p {
			@include mq($from: wide) {
				width: 90%;
			}
		}

		a.selector {
			.gamme-icon {
				width: 100px;
				margin-bottom: 20px;
			}
		}
	}
}

////// PHOTOS ILLU LAMPADAIRE //////
.cbp-so-scroller-2 {
	position: absolute;
	z-index: 10;
	width: 33%;
	width: percentage(1/3);

	@include mq($from: tablet) {
		margin-top: -100px;
	}
	@include mq($from: desktop) {
		margin-top: -150px;
	}
	@include mq($from: wide) {
		margin-top: -220px;
	}
}

.cbp-so-section-2 {
	height: 800px;

	@include mq($from: wide) {
		height: 960px;
	}
}

/* Sides */
.cbp-so-side-2 {
	left: 40px;

	@include mq($from: desktop) {
		left: 54px;
	}
	@include mq($from: wide) {
		left: 66px;
	}
}

.cbp-so-side-illu1 {
	z-index: 0;

	@include mq($from: desktop) {
		width: 65%;
	}
}
.cbp-so-side-illu2 {
	width: 60%;
	left: 70px;
	z-index: -1;

	@include mq($from: desktop) {
		width: 55%;
		left: 90px;
	}
	@include mq($from: wide) {
		width: 53%;
		left: 110px;
	}
}

/* Clear floats of children */
.cbp-so-side:before,
.cbp-so-side:after {
	content: " ";
	display: table;
}

.cbp-so-side:after {
	clear: both;
}

.cbp-so-side img {
	width: 100%;
	height: auto;
}

/* Initial state (hidden or anything else) */
.cbp-so-init .cbp-so-side {
	opacity: 0;
}

.cbp-so-init .cbp-so-side-illu1 {
	top: 120px;
	transition: top 0.8s, opacity 0.5s;
}

.cbp-so-init .cbp-so-side-illu2 {
	top: 300px;
	transition: top 1.4s, opacity 1s;
}

/* Animated state */
.cbp-so-section.cbp-so-animate .cbp-so-side-illu1,
.cbp-so-section.cbp-so-animate .cbp-so-side-illu2 {
	opacity: 1;
}	
.cbp-so-section.cbp-so-animate .cbp-so-side-illu1 {
	@include mq($from: tablet) {
		top: 0;
	}
}
.cbp-so-section.cbp-so-animate .cbp-so-side-illu2 {
	@include mq($from: tablet) {
		top: 100px;
	}
	@include mq($from: desktop) {
		top: 140px;
	}
	@include mq($from: wide) {
		top: 210px;
	}
}
//////////////////////////

.relative{
	position: relative;
}

.slick-slider-home-arrows{
	position: absolute;
	z-index: 1000;
	top: 50%;
	left: 30px;
	right: 30px;
	height: 41px;
	transform: translate3d(0,-50%,0);

	pointer-events: none;

	li{
		transition: transform 0.3s ease-in-out;
		float: left;
		cursor: pointer;
		pointer-events: auto;
		&.slick-slider-home-arrow--next{
			float: right;
			span {
				background: transparent url('#{$image-path}arrow-right.png') 0 0 no-repeat;
			}
		}
		span {
			display: block;
			width: 25px;
			height: 41px;
			background: transparent url('#{$image-path}arrow-left.png') 0 0 no-repeat;
		}

		.no-touch &:hover,
		.no-touch &:focus,
		.touch &:active {
			transform: translate3d(-10px,0,0);
			&.slick-slider-home-arrow--next {
				transform: translate3d(10px,0,0);
			}
		}
	}
}

.slick-slider-home-dots{
	//position: absolute;
	//top: -20px;
	//left: 0;
	//
	//@include mq($from: tablet) {
	//	left: -42%;
	//	top: auto;
	//	bottom: -30px;
	//
	//	.home & {
	//		bottom: 20px;
	//	}
	//
	//}

	li{
		float:left;
		margin: 0 1em 0 0;

		button{
			transition: background-color 0.3s ease-in-out;
			display: inline-block;
			width : 8px;
			height: 8px;
			border-radius: 50%;
			padding: 0;
			background-color: #767676;
			border: none;
			text-indent: -5000px;
		}

		.no-touch &:hover,
		.no-touch &:focus,
		.touch &:active,
		&.slick-active{
			button{
				background-color: $color-2;
			}
		}
	}

}

/* 
########   ########    #######   ########   ##     ##  ####  ########    ######    
##     ##  ##     ##  ##     ##  ##     ##  ##     ##   ##      ##      ##    ##   
##     ##  ##     ##  ##     ##  ##     ##  ##     ##   ##      ##      ##         
########   ########   ##     ##  ##     ##  ##     ##   ##      ##       ######    
##         ##   ##    ##     ##  ##     ##  ##     ##   ##      ##            ##   
##         ##    ##   ##     ##  ##     ##  ##     ##   ##      ##      ##    ##   
##         ##     ##   #######   ########    #######   ####     ##       ######    
*/
////// NAV PRODUITS [MOBILE] //////
.nav-product--container {
	.no-touch & {
		@include mq($from: desktop) {
			display: none;
		}
	}
}

.nav-product--solution {
	text-transform: uppercase;
	@include font-size(16);
	margin: 0 0 1em 0;

	&:before {
		top: 0.6em;
	}
}

.nav-product--type--item {
	@include clearfix;
	margin: 0 0 1.4em 0;
	border-bottom: 1px solid $color-text-ultralight;
	border-color: rgba($color-text-ultralight, 0.4);

	@include mq($from: tablet) {
		border: none;

		&:first-of-type {
			width: 50%;
			float: left;
			margin-right: 10%;
			padding-right: 40px;
			border-right: 1px solid $color-text-ultralight;
			border-color: rgba($color-text-ultralight, 0.4);
		}
		&:last-of-type {
			width: 40%;
			float: left;
		}
	}
}

.nav-product--gamme--item {
	@include mq($from: mobileLandscape) {
		width: 50%;
		width: percentage(1/2);
		float: left;
		margin: 0;
	}
}

.nav-product--type--item--link,
.nav-product--gamme--item--link {
	display: inline-block;
	margin: 0 0 0.6em 0;
}

.nav-product--type--item--link {
	font-family: $font-light;
	@include font-size(16);
	color: $color-2-darken;
	color: darken($color-2-darken, 5%);
	margin-top: 1.2em;
}

.nav-product--product--item {
	margin: 0 0 0.4em 0;
}

/////////////////////////

////// PHOTO INTRO //////
.cbp-so-side-produit {
	right: 5px;
	z-index: 0;

	@include mq($from: tablet) {
		width: 100%;
		top: 50px;
	}
	@include mq($from: desktop) {
		width: 80%;
		top: 45px;
	}
	@include mq($from: wide) {
		right: 12px;
		top: 55px;
	}
}

/* Initial state (hidden or anything else) */
.cbp-so-init .cbp-so-side-produit {
	top: 220px;
}

/* Animated state */
.cbp-so-section.cbp-so-animate .cbp-so-side-produit {
	opacity: 1;

	@include mq($from: tablet) {
		top: 85px;
	}
	@include mq($from: desktop) {
		top: 80px;
	}
	@include mq($from: wide) {
		top: 95px;
	}
}
//////////////////////////

////// DÉTAIL PRODUIT //////
.produit-detail {
	.main-list {
		margin-top: 0;

		@include mq($from: desktop) {
			margin: 0;
		}
	}
}

.produit-leading {
	@include font-size(15.5);
	font-style: italic;
	color: $color-1;
	line-height: 1.5;
	margin-bottom: 1.5em;

}
//////////////////////////


/* 
 ######    ########   ########   ##     ##  ####   ######    ########    ######    
##    ##   ##         ##     ##  ##     ##   ##   ##    ##   ##         ##    ##   
##         ##         ##     ##  ##     ##   ##   ##         ##         ##         
 ######    ######     ########   ##     ##   ##   ##         ######      ######    
      ##   ##         ##   ##     ##   ##    ##   ##         ##               ##   
##    ##   ##         ##    ##     ## ##     ##   ##    ##   ##         ##    ##   
 ######    ########   ##     ##     ###     ####   ######    ########    ######    
*/
.title--content {
	margin-top: 40px;

	@include mq($from: desktop) {
		margin-top: 60px;
	}
	@include mq($from: wide) {
		margin-top: 80px;
	}

	body:not(.produit-detail) & {
		&:first-of-type {
			margin-top: 0;
		}
	}
}

.service .main-list {
	margin: 1.5em 0 0 0;
}

.service-list {

	li {
		margin: 0 0 10px 0;
	}

	img {
		width: 72px;
		height: 72px;
		border: 1px solid $color-1;
		border-color: rgba($color-1, 0.5);

		@include mq(desktop, wide) {
			width: 80px;
			height: 80px;
		}
	}

	.service-list--detail {
		display: inline-block;
		margin-left: 10px;
		vertical-align: middle;

		&:before {
			background-color: $color-1;
			background-color: rgba($color-1, 0.6);
			width: 18px;
			left: -24px;
			top: 11px;
		}
	}
}


/* 
########   ##    ##   ########   ########   
   ##       ##  ##    ##     ##  ##         
   ##        ####     ##     ##  ##         
   ##         ##      ########   ######     
   ##         ##      ##         ##         
   ##         ##      ##         ##         
   ##         ##      ##         ########   
*/
.desc-list--header {
	@include clearfix;
	margin-bottom: 20px;
	-webkit-backface-visibility: hidden;
}

.desc-list--image {
	width: 50% !important;
	width: percentage(1/2) !important;
	height: auto;
	float: left;
}

a.desc-list--image {
	overflow: hidden;
	border: none;

	img {
		transform: scale(1);
		transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	}

	.no-touch & {
		&:hover {
			img {
				transform: scale(1.1);
				opacity: 0.9;
				transition: transform 1.5s ease-in-out, opacity 0.75s ease-in-out;
			}
		}
	}
}

.desc-list--image--inner {
	width: 100%;
	height: auto;
}

.desc-list--title {
	width: 40%;
	width: percentage(1/2);
	float: left;

	@include mq($from: tablet) {
		width: 38%;
		@include font-size(22);
	}
	@include mq($from: desktop) {
		width: 40%;
		@include font-size(20);
	}
	@include mq($from: wide) {
		width: 50%;
		@include font-size(22);
	}

	a {
		border: none;
	}
}


/* 
########   ########   ########   
##     ##  ##         ##         
##     ##  ##         ##         
########   ######     ######     
##   ##    ##         ##         
##    ##   ##         ##         
##     ##  ########   ##         
*/
////// AWARDS //////
.desc-list--title--award {
	top: 20px;
	@include font-size(20);

	@include mq($from: tablet) {
		top: 40px;
		@include font-size(24);
	}
	@include mq($from: desktop) {
		top: 20px;
		@include font-size(20);
	}
	@include mq($from: wide) {
		top: 30px;
		@include font-size(22);
	}
}
////////////////////

////// GMAPS //////
.wrapper--map-prez {

	.two-columns {

		@include mq($from: tablet) {
			margin-top: 6px;
		}
		@include mq($from: desktop) {
			margin-top: 10px;
		}
		@include mq($from: wide) {
			margin-top: 12px;
		}
	}
}

.map {
	height: 350px;
	border-top: 1px solid $color-text-ultralight;
	border-color: lighten($color-text-ultralight, 10%);

	@include mq($from: tablet) {
		height: 350px;
	}
	@include mq($from: desktop) {
		height: 400px;
	}
	@include mq($from: wide) {
		height: 450px;
	}

	.gm-style-cc {
		display: none;
	}
}

.map-overlay {
	width: auto;
	padding: 1px;
	background-color: #fff;
	position: relative;

	box-shadow: 0 0 5px rgba($black, 0.3);
}

.map-overlay .close {
	display: block;
	width: auto;
	height: auto;
	position: absolute;
	top: -30px;
	right: -6px;
	z-index: 5;

	transition: transform 0.2s ease-in-out;

	&:before {
		font-family: 'dti';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		display: inline-block;
		content: "\e607";
		color: $color-1;
		@include font-size(30);
		height: auto;
		width: auto;

		transition: transform 0.2s ease-in-out;
	}

	.no-touch & {
		&:hover {
			transform: rotate(180deg);
		}
	}
}

.map-overlay img {
	width: 300px;
	height: auto;

	@include mq($from: tablet) {
		width: 350px;
	}
	@include mq($from: desktop) {
		width: 380px;
	}
	@include mq($from: wide) {
		width: 400px;
	}
}

.map-overlay h6, .map-overlay p {
	@include font-size(12);
	margin: 0;
	line-height: 1.25em;
	color: $color-text;

	@include mq($from: desktop) {
		@include font-size(13);
	}
}

.map-overlay h6 {
	font-family: $font-number-2;
	padding: 14px 12px 0 12px;
}
.map-overlay p {
	font-family: $font-light;
	padding: 0 12px 12px 12px;
}

.map-overlay strong {
	display: block;
	margin: 10px 0;
	font-weight: bold;
}

.wrapper--legend {
	padding-bottom: 0;
}

.legend {
	margin: 0;

	@include mq($from: tablet) {
		margin: 20px 0 0 0;
	}

	li {
		display: inline-block;
		min-width: 45px;

		.icon {
			display: block;
			width: 33px;
			height: 40px;
			margin: 0 auto;
		}

		&.lukida .icon {
			background: transparent url('#{$image-path}reference/lukida.png') 0 0 no-repeat;
			background-size: 33px 40px;

			@include retina {
				background-image: url('#{$image-path}reference/lukida@2x.png');
				background-size: 100% 100%;
			}
		}
		&.aol .icon {
			background: transparent url('#{$image-path}reference/aol.png') 0 0 no-repeat;
			background-size: 33px 40px;

			@include retina {
				background-image: url('#{$image-path}reference/aol@2x.png');
				background-size: 100% 100%;
			}
		}
		&.antares .icon {
			background: transparent url('#{$image-path}reference/antares.png') 0 0 no-repeat;
			background-size: 33px 40px;

			@include retina {
				background-image: url('#{$image-path}reference/antares@2x.png');
				background-size: 100% 100%;
			}
		}

		.label {
			display: block;
			margin-top: 4px;

			font-family: $font-base;
			@include font-size(12);
			text-align: center;
			color: $color-text-light;

			.no-touch & {
				-webkit-font-smoothing: antialiased;
			}
		}
	}
}
////////////////////


/* 
########      ###     ########   ########   ##    ##   ########   ########   
##     ##    ## ##    ##     ##     ##      ###   ##   ##         ##     ##  
##     ##   ##   ##   ##     ##     ##      ####  ##   ##         ##     ##  
########   ##     ##  ########      ##      ## ## ##   ######     ########   
##         #########  ##   ##       ##      ##  ####   ##         ##   ##    
##         ##     ##  ##    ##      ##      ##   ###   ##         ##    ##   
##         ##     ##  ##     ##     ##      ##    ##   ########   ##     ##  
*/
////// LISTE PARTENAIRES //////
.desc-list--partner {

	> li {
		width: 100%;
		margin-bottom: 40px;

		@include mq($from: tablet) {
			margin-bottom: 60px;
		}
		@include mq($from: desktop) {
			margin-bottom: 80px;
		}
		@include mq($from: wide) {
			margin-bottom: 100px;
		}
	}

	.desc-list--header {
		margin-bottom: 25px;

		@include mq($from: wide) {
			margin-bottom: 35px;
		}
	}

	.desc-list--image {
		border: 1px solid $color-1;
		border-color: rgba($color-1, 0.5);

		@include mq($from: tablet) {
			width: 25% !important;
			width: percentage(1/4) !important;
		}
	}

	.desc-list--title {
		top: 20px;

		@include mq($from: tablet) {
			top: 10px;
			width: 60%;
		}
		@include mq($from: desktop) {
			top: 70px;
			@include font-size(24);
			width: 40%;
		}
		@include mq($from: wide) {
			top: 85px;
			@include font-size(28);
		}
	}
}
////////////////////

/* 
########   ##     ##   #######   ########    #######    ######    
##     ##  ##     ##  ##     ##     ##      ##     ##  ##    ##   
##     ##  ##     ##  ##     ##     ##      ##     ##  ##         
########   #########  ##     ##     ##      ##     ##   ######    
##         ##     ##  ##     ##     ##      ##     ##        ##   
##         ##     ##  ##     ##     ##      ##     ##  ##    ##   
##         ##     ##   #######      ##       #######    ######    
*/
.separator {
	@include clearfix;
	clear: both;
	border-top: 1px solid $color-text-ultralight;
	border-color: rgba($color-text-ultralight, 0.5);
	margin: 10px 0 30px 0;
}

////// VIDÉOS YOUTUBE //////
.video-baseline {
	@include clearfix;
	float: left;
	margin-top: 10px;

	@include font-size(12);
	display: inline-block;

	@include mq($until: desktop) {
		margin-bottom: 10px;
	}
	@include mq($from: tablet) {
		float: right;
	}
	@include mq($from: desktop) {
		width: 75%;
		text-align: right;
		@include font-size(13);
	}
	@include mq($from: wide) {
		@include font-size(14);
		height: 26px;
		line-height: 26px;
		margin: 18px 12px 0 0;
	}
}

.video-link {
	position: relative;
	color: $color-text-light;
	color: lighten($color-text-light, 25%);
	padding-right: 14px;

	&:after {
		font-family: 'dti';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

	    content: "\e603";

	    @include font-size(10);
	    position: absolute;
	    right: 0;
	    top: 4px;

		transition: right 0.3s ease-in-out;

		@include mq($until: desktop) {
			.touch & {
				top: auto;
	    		bottom: 1px;
			}
		}
		@include mq($from: desktop) {
			top: 5px;
		}
		@include mq($from: wide) {
			top: 6px;
		}
	}

	.no-touch & {

		&:hover {
			color: darken($color-text-light, 10%);

			&:after {
				right: -5px;

				@include mq($from: wide) {
					right: -6px;
				}
			}
		}
	}
}

.wrapper--content--video {
	
	@include mq($from: tablet) {
		padding-bottom: 20px !important;
	}
}

.title-section--video {
	display: block;
	width: 100%;
}

.video-list {
	@include clearfix;

	li {
		width: auto;
		float: left;
		width: 100%;
		position: relative;

		@include mq($from: tablet) {
			width: 49%;

			&:nth-of-type(2n) {
				margin-left: 2%;
			}
		}
		@include mq($from: desktop) {
			width: 33%;
			margin: 0 0.5% 0.5% 0;

			&:nth-of-type(2n) {
				margin: 0 0.5% 0.5% 0;
			}
			&:nth-of-type(3n) {
				margin: 0;
			}
		}
		@include mq($from: wide) {
			width: 24%;
			margin: 0 1% 1% 0;

			&:nth-of-type(3n),
			&:nth-of-type(2n) {
				margin: 0 1% 1% 0;
			}
			&:nth-of-type(4n) {
				margin-left: 0;
			}
		}

		.video-title {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			background-color: rgba($color-1, 0.7);
			color: $color-bg;
			z-index: 10;
			padding: 10px 20px;
			@include font-size(12);
			font-weight: 100;
		}
	}
}


////// MASONRY + FILTERS //////
.gallery-filters {
	@include clearfix;
	float: right;
	margin-top: 10px;

	@include mq($until: desktop) {
		margin-bottom: 10px;
	}
	@include mq($from: desktop) {
		width: 75%;
		text-align: right;
	}
	@include mq($from: wide) {
		margin-top: 18px;
	}
}

.filter-item {
	color: $color-text-light;
	color: lighten($color-text-light, 25%);
	@include font-size(12);
	display: inline-block;
	margin: 0 0 0.8em 0;

	transition: color 0.3s ease-in-out;

	@include mq($until: desktop) {
		padding: 0 6px;
		height: 24px;
		line-height: 24px;
		border: 1px solid $color-text-ultralight;
	}

	@include mq($from: desktop) {
		@include font-size(13);
		margin: 0 10px 0 0;
	}
	@include mq($from: wide) {
		@include font-size(14);
		margin: 0 12px 0 0;
		height: 26px;
		line-height: 26px;
	}

	.no-touch & {

		&:hover {
			cursor: pointer;
			color: darken($color-text-light, 10%);
		}
	}
}

.filter-item-current {
	color: darken($color-text-light, 10%);

	@include mq($until: desktop) {
		border-color: lighten($color-text-light, 15%);
	}
}

.gallery-baseline {
	@include clearfix;
	clear: both;
	width: 100%;
	padding-left: 0;
	padding-bottom: 0;
	border-top: 1px solid $color-text-ultralight;
	border-color: rgba($color-text-ultralight, 0.5);

	@include mq($from: tablet) {
		padding: 28px 0 10px 0 !important;
	}
	@include mq($from: desktop) {
		padding: 38px 0 15px 0 !important;
	}

	.catchphrase {
		@include mq($from: desktop) {
			line-height: 1.25em;
		}
		@include mq($from: wide) {
			@include font-size(28);
		}
	}
}

#gallery-content{
	position: relative;
	overflow: hidden;
	width:100%;
}

.gallery-list {
	width: 100%;

	li {
		position: relative;
		// width: 100%;
		margin: 0;
		padding: 0 0 2px 0;
		overflow: hidden;
		display: block;
		opacity: 1;

		transition: all 0.5s;

		&.wookmark-inactive {
		  visibility: hidden;
		  opacity: 0;
		}

		.produit-detail & {
			width: 50%;
			width: percentage(1/2);
			padding: 2px;
		}

		@include mq($from: tablet) {
			// width: 50%;
			// width: percentage(1/2);
			padding: 2px;
		}
		@include mq($from: desktop) {
			// width: 33%;
			// width: percentage(1/3);
		}
		@include mq($from: wide) {
			// width: 25%;
			// width: percentage(1/4);

			.produit-detail & {
				width: 24.9%;
			}
		}

		a {
			display: block;
			height: 100%;
			width: 100%;
		}

		.mask-hover {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			height: calc(100% - 2px);
			overflow: hidden;

			.no-touch & {
				background-color: $color-1;
				background-color: rgba($color-1, 0.9);
				top: 100%;
				opacity: 0;
				transition: top 0.3s ease-in-out, opacity 0.5s ease-in-out;
			}

			@include mq($from: tablet) {
				height: calc(100% - 4px);
				width: calc(100% - 4px);
			}
			
			.legend {
				position: absolute;
				width: 66%;
				width: percentage(2/3);

				font-family: $font-light;
				@include font-size(28);
				line-height: 1.2em;
				color: $white;

				@include mq($from: desktop) {
					width: 50%;
					width: percentage(1/2);
					@include font-size(25);
				}
				@include mq($from: wide) {
					@include font-size(23);
				}

				.touch & {
					background-color: $color-1;
					background-color: rgba($color-1, 0.9);
					width: 100%;
					height: auto;
					padding: 12px 30px 14px 14px;
					bottom: 0;

					@include font-size(16);
				}

				.no-touch & {
					-webkit-font-smoothing: antialiased;

					width: 50%;
					left: 24px;
					top: 150px;
					opacity: 0;

					transition: top 0.55s ease-in-out, opacity 0.3s ease-in-out;
				} 
			}

			.icon-plus {
				position: absolute;
				@include font-size(24);
				color: $white;
				border: none;
				-webkit-backface-visibility: hidden;

				@include mq($from: tablet) {
					@include font-size(20);

					.produit-detail & {
						@include font-size(32);
					}
				}
				@include mq($from: wide) {
					.produit-detail & {
						@include font-size(28);
					}
				}

				.touch & {
					top: auto;
					right: 14px;
					bottom: 14px;
					@include font-size(18);
				}

				.no-touch & {
					right: 20px;
					bottom: -100px;
					opacity: 0;

					transition: bottom 0.55s ease-in-out, opacity 0.3s ease-in-out;

					&:before {
						transition: transform 0.2s ease-in-out;
					}

					&:hover {
						&:before {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		img {
			background-color: $color-1;
			width: 100%;
			height: auto;
		}

		.no-touch & {
			&:hover {

				.mask-hover {
					top: 0;
					opacity: 1;

					@include mq($from: tablet) {
						top: 2px;
					}

					.legend {
						top: 20px;
						opacity: 1;
					}

					.icon-plus {
						bottom: 20px;
						opacity: 1;
					}
				}
			}
		}

		.produit-detail & {
			.no-touch & {
				&:hover {
					.mask-hover {
						.icon-plus {
							
						}
					}
				}
			}
		}
	}
}

.progress-bar-overlay {
	transition: opactiy 0.2s ease-in-out, visibility 0.2s ease-in-out;
	position: fixed;
	z-index: 1050;
	top: 0;
	left: 0;
	right: 0;
	bottom:0;
	background-color: $white;
	background-color: rgba($white,.9);
	opacity: 1;
	visibility: visible;

	&.fade{
		opacity: 0;
		visibility: hidden;
	}

}

.progress-bar-content {
	position: absolute;
	top:50%;
	left:20%;
	right:20%;
	text-align: center;
}

.progress-bar-text{
	font-family: $font-number;
	padding:10px 0;
}

.progress-bar {
	background-color: $color-2;
	height: 3px;
	width: 0;
	margin:-1px 0 0 0;
	z-index:1000;
	// box-shadow: 0 1px 3px rgba(11, 194, 11, 0.2);
	transition: width 0.1s ease-out;
}
//////////////////////////

////// LIGHTGALLERY //////
.lg-backdrop {
	background-color: $color-1;
	background-color: rgba($color-1, 0.95);
}

.lg-toolbar {
	background-color: transparent;
}

.lg-toolbar .lg-icon {
	background-color: transparent;
    border-radius: 0;
    color: $color-text-ultralight;
    cursor: pointer;
    display: block;
	margin: 0;
	padding: 0;
	height: auto;
	width: auto;
	position: absolute;
	top: 20px;
	right: 20px;

	@include font-size(24);

	transition: color 0.2s ease-in-out;

	.touch & {
		height: 40px;
		line-height: 40px;
		width: 40px;
		top: 0;
		right: 0;
	}
}

.lg-toolbar .lg-close {
	&:after {
		height: auto;
		width: auto;
		transition: transform 0.2s ease-in-out;
	}

	.no-touch & {
		&:hover {
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
	background-color: transparent;
    border-radius: 0;
    color: $color-text-ultralight;
    cursor: pointer;
    display: block;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 50%;

	@include font-size(20);

	transition: color 0.2s ease-in-out;

	.touch & {
		display: none;
	}
}

.lg-actions .lg-prev {
	
}

.lg-image {
	position: relative;
	left: -5px;
}

.lg-sub-html {
	background-color: transparent;
	padding-bottom: 20px;

	font-family: $font-number;
	font-weight: 100;
	@include font-size(13);
	color: rgba($color-bg, 0.6);
}
//////////////////////////


/* 
########   ########   ########    ######     ######    ########   
##     ##  ##     ##  ##         ##    ##   ##    ##   ##         
##     ##  ##     ##  ##         ##         ##         ##         
########   ########   ######      ######     ######    ######     
##         ##   ##    ##               ##         ##   ##         
##         ##    ##   ##         ##    ##   ##    ##   ##         
##         ##     ##  ########    ######     ######    ########   
*/
.main-list--press {

	a {
		.no-touch & {
			&:hover {
				color: rgba($color-1, 0.7) !important;
			}
		}
	}

	.list-press--type {
		display: inline-block;
		color: $color-2;
		padding: 0;
		margin-top: 2.2em;
		border-bottom: 1px solid $color-2;

		&:first-of-type {
			margin-top: 0;
		}

		&:before {
			display: none;
		}
	}

	.list-press--gamme {
		text-transform: uppercase;
		padding: 0;

		&.margin {
			margin-top: 1.8em;
		}

		&:before {
			display: none;
		}
	}
}

.presse-download span{
	font-size:.8em;
}

form.dossier-presse-form {
	width: 100%;
	padding: 15px;
	margin-top: 10px;
	background-color: $color-3;

	@include mq($from: wide) {
		padding: 20px;
	}

	.field {
		margin: 0 0 20px 0;

		li.error input {
			border: 2px solid $color-warning;
		}
	}

	label {
		display: block;
		margin: 0 0 0.2em 0;
		font-family: $font-light;

		&:first-of-type {
			margin-top: 0;
		}
	}

	input {
		width: 100%;
		height: 36px;
		line-height: 36px;
		padding: 0 10px;
		border: none;

		@include font-size(13);
	}

	button.valid {
		float: none;
		height: 38px;
		line-height: 38px;

		@include mq($from: tablet) {
			width: 100%;
		}
	}
}


/* 
   ###      ######    ########   ##     ##  
  ## ##    ##    ##      ##      ##     ##  
 ##   ##   ##            ##      ##     ##  
##     ##  ##            ##      ##     ##  
#########  ##            ##      ##     ##  
##     ##  ##    ##      ##      ##     ##  
##     ##   ######       ##       #######   
*/
////// ANIM PHOTOS //////
.cbp-so-side-actu1 {
	right: 5px;
	z-index: 0;
	width: 65%;

	@include mq($from: tablet) {
		top: 80px;
	}
	@include mq($from: desktop) {
		top: 105px;
	}
	@include mq($from: wide) {
		right: 12px;
		top: 135px;
	}
}
.cbp-so-side-actu2 {
	left: 0;
	z-index: -1;
	width: 65%;

	@include mq($from: tablet) {
		top: 45px;
	}
	@include mq($from: desktop) {
		top: 55px;
	}
	@include mq($from: wide) {
		top: 65px;
	}
}

.cbp-so-init .cbp-so-side-actu1 {
	@include mq($from: tablet) {
		top: 120px;
	}
	@include mq($from: wide) {
		top: 130px;
	}
}

.cbp-so-init .cbp-so-side-actu2 {
	top: 240px;
	transition: top 1s, opacity 1s;
}

/* Animated state */
.cbp-so-section.cbp-so-animate .cbp-so-side-actu1,
.cbp-so-section.cbp-so-animate .cbp-so-side-actu2 {
	opacity: 1;
}	
.cbp-so-section.cbp-so-animate .cbp-so-side-actu1 {
	@include mq($from: tablet) {
		top: 70px;
	}
	@include mq($from: desktop) {
		top: 75px;
	}
}
.cbp-so-section.cbp-so-animate .cbp-so-side-actu2 {
	@include mq($from: tablet) {
		top: 125px;
	}
	@include mq($from: desktop) {
		top: 150px;
	}
	@include mq($from: wide) {
		top: 180px;
	}
}
/////////////////////

.actualite .title-section {
	margin-bottom: 1.2em;

	@include mq($from: tablet) {
		margin-bottom: 1.5em;
	}
	@include mq($from: desktop) {
		margin-bottom: 1.8em;
	}
	@include mq($from: wide) {
		margin-bottom: 2em;
	}
}

.actu--list-actu {
	
	li {

		@include mq($from: tablet) {
			padding-right: 20px;
		}
		@include mq($from: wide) {
			padding-right: 40px;
		}

		.actu--date {
			margin: 0 0 1em 0;
			@include font-size(14);
			font-weight: 100;
			color: lighten($color-text-light, 20%);
		}

		.title-lev2 {
			color: $color-text;

			@include mq(tablet, desktop) {
				@include font-size(17);
			}
			@include mq($from: desktop) {
				width: 80%;
				@include font-size(19);
			}
			@include mq($from: wide) {
				width: 70%;
			}

			a {
				color: $color-text;

				.no-touch & {
					&:hover {
						color: $color-1;
					}
				}
			}
		}

		p {
			@include mq(tablet, desktop) {
				@include font-size(13);
			}
		}

		.link-more {
			margin: 1.5em 0 0 0;

			@include mq($from: wide) {
				margin: 1.8em 0 0 0;
			}
		}
	}
}

////// PAGINATION //////
.pagination {
	width: 100%;
	margin: 10px 0 0 0;

	@include mq($from: tablet) {
		text-align: left;
		margin: 20px 0 0 0;
	}
	@include mq($from: wide) {
		margin: 40px 0 0 0;
	}

	.multipage {
		display: inline-block;
		@include clearfix;
		height: auto;
		width: auto;

		li {
			display: inline-block;
			height: 24px;
			line-height: 24px;
			width: 24px;
			padding: 0;
			margin: 0;

			a {
				display: block;
				height: 100%;
				width: 100%;
				line-height: 24px;
				color: $color-1;
				color: rgba($color-1, 0.8);
				text-align: center;
				@include font-size(12);
				font-family: $font-light;
				border: 1px solid $color-1;
				border-color: rgba($color-1, 0.6);

				transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;

				.no-touch & {
					-webkit-font-smoothing: antialiased;

					&:hover {
						background-color: rgba($color-1, 0.8);
						background-color: $color-2-darken;
						border-color: rgba($color-1, 0.5);
						border-color: $color-2-darken;
						color: $white;
					}
				}
			}

			&.current {

				a {
					background-color: $color-1;
					border-color: $color-1;
					color: $white;
				}
			}
		}
	}
}
/////////////

////// DETAIL ACTU > Photos //////
.cbp-so-side-actudetail1 {
	width: 40%;
}
.cbp-so-side-actu2 {
	width: 80%;
}
/* Animated state */
.cbp-so-section.cbp-so-animate .cbp-so-side-actu2 {
	@include mq($from: tablet) {
		top: 105px;
	}
	@include mq($from: desktop) {
		top: 130px;
	}
	@include mq($from: wide) {
		top: 152px;
	}
}
/////////////////////

.actu-detail--date {
	margin: 0 0 1em 0;

	font-family: $font-number;
	@include font-size(16);
	font-weight: 100;
	color: lighten($color-text-light, 20%);
}

/* 
 ######     #######   ##    ##   ########      ###      ######    ########   
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
##         ##     ##  ##  ####      ##      #########  ##            ##      
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
 ######     #######   ##    ##      ##      ##     ##   ######       ##      
*/
////// FORMULAIRE //////
form.contact-form > ul {
	margin: 0;

	@include mq($from: desktop) {
		float: left;
		width: 50%;
		width: percentage(1/2);

		&:first-of-type {
			padding: 0 10px 0 0;
		}
		&:last-of-type {
			padding: 0 0 0 10px;
		}
	}
}

.input,
.textarea {
	position: relative;
	z-index: 1;
	display: block;
	width: 100%;
	vertical-align: top;
	background-color: $color-3;
}

.input {
	display: block;
	height: 48px;
	overflow: hidden;
}

.input__field {
	position: absolute;
	top: 0;
	right: 100%;
	display: block;
	width: 70%;
	height: 100%;
	padding: 15px 10px 12px 10px;
	border: 2px solid $color-3;
	border-radius: 0;

	font-family: $font-number;
	@include font-size(12);
	color: $color-text-light;
	-webkit-appearance: none; /* for box shadows to show on iOS */

	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}
}

.input__field:focus {
	outline: none;
}

.input__label {
	z-index: 10;
	display: block;
	float: right;
	padding: 0 10px;
	width: 100%;
	height: 100%;
	text-align: left;
	cursor: text;
	transform-origin: 0% 50%;

	font-family: $font-base;
	color: $color-1;
	color: lighten($color-1, 15%);
	@include font-size(12);

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	-webkit-touch-callout: none;
	user-select: none;

	transition: transform 0.5s, color 0.5s ease-in-out;
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.input__label-content {
	position: relative;
	display: block;
	width: 100%;
	padding: 15px 0 12px 0;
}

.input__field:focus,
.input--filled .input__field {
	transform: translate3d(100%, 0, 0);
	transition-delay: 0.06s;
}

.input__field:focus + .input__label,
.input--filled .input__label  {
	transform: translate3d(70%, 0, 0);
	pointer-events: none;
	color: lighten($color-1, 5%);
}

.input.error, .textarea.error {
	border: 2px solid $color-warning;

	.input__label-content {
		color: $color-warning;
	}
}

/*** textarea ***/
.textarea {
	height: 180px;
	
	@include mq($from: desktop) {
		height: 234px;
	}
}

.input__textarea {
	width: 100%;
	height: 100%;
	border: none;
	background-color: $color-3;

	padding: 10px;
	font-family: $font-base;
	color: $color-text-light;
	@include font-size(12);

	.no-touch & {
		-webkit-font-smoothing: antialiased;
	}
}

/*** button ***/
.button__container {
	height: 46px;
}

.button {
	float: left;
	width: 100%;
	display: block;
	overflow: hidden;
	margin: 0;
	padding-top: 2px;
	height: 46px;
	line-height: 46px;
	border: 2px solid;
	background: none;

	font-family: $font-base;
	@include font-size(13);
	text-transform: uppercase;
	color: $color-1;
	position: relative;
	z-index: 1;

	.no-touch & {
		-webkit-backface-visibility: hidden;
		-moz-osx-font-smoothing: grayscale;
	}

	@include mq($from: tablet) {
		width: 40%;
		float: right;
	}
	@include mq($from: desktop) {
		width: 50%;
	}

	&:before {
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $color-1;

		font-family: $font-light;
		@include font-size(13);
		text-transform: uppercase;
		color: $color-3;

		transform: translate3d(-100%, 0, 0);

		.no-touch & {
			-webkit-backface-visibility: hidden;
			-moz-osx-font-smoothing: grayscale;
		}
	}

	&:focus {
		outline: none;
	}

	> span {
		display: block;
		vertical-align: middle;
	}

	&:before,
	> span {
		transition: transform 0.3s;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}

	.no-touch & {
		&:hover {

			&:before {
				transform: translate3d(0, 0, 0);
			}
			> span {
				transform: translate3d(0, 100%, 0);
			}
		}
	}	
}

.formError {
	position: absolute;
	height: auto;
}

.formError .formErrorContent {
	background-color: $color-1;
	color: $white;
	min-width: 120px;
	@include font-size(11);
	height: 20px;
	line-height: 20px;
}
/////////////

/* 
########      ###      #######   
##           ## ##    ##     ##  
##          ##   ##   ##     ##  
######     ##     ##  ##     ##  
##         #########  ##  ## ##  
##         ##     ##  ##    ##   
##         ##     ##   ##### ##  
*/
.faq-title-rubric {
	margin-top: 2.5em;

	&:first-of-type {
		margin-top: 0;
	}
}

////// NOTRE DÉMARCHE //////

.notre-demarche__container {
	div.editor-text {
		&>ol {
			& > li {
				list-style-type: upper-roman;

					div>ol {
						margin-left: 50px;
						&>li {
							list-style-type: lower-alpha;
						}
					}
			}
		}
	}
}


/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
footer {
	position: relative;
	background-color: $color-1;
	color: $color-text-ultralight;
	color: rgba($color-text-ultralight, .75);
	z-index: 20;

	p, a {
		color: $color-text-ultralight;
		color: rgba($color-text-ultralight, .75);
	}
}

.footer--partners {
	background-color: #ffffff;
	border-bottom: 1px solid $color-text-ultralight;
	border-color: rgba($color-text-ultralight, .15);

	@include mq($from: tablet) {
		padding: 44px 0 22px 0;
	}

	ul {
		text-align: center;
		margin: 0 auto;
		max-width: 900px;
		display: flex;
		justify-content: space-around;

		li {
			margin: 0;

			@include mq($from: mobileLandscape) {
				margin-right: 20px;
			}
			@include mq($from: tablet) {
				margin-right: 40px;
			}
			@include mq($from: desktop) {
				margin-right: 50px;
			}
			@include mq($from: wide) {
				margin-right: 70px;
			}

			&:last-of-type {
				margin: 0;
			}
			
			a {
				display: block;
				text-indent: -9999px;

				&.logo-hei {
					height: 30px;
					width: 30px;
					background: transparent url('#{$image-path}partners/hei.png') 0 0 no-repeat;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/hei@2x.png');
					}

					@include mq($from: tablet) {
						width: 38px;
						height: 38px;
					}
					@include mq($from: desktop) {
						width: 45px;
						height: 45px;
					}
				}

				&.logo-sig {
					height: 30px;
					width: 30px;
					background: transparent url('#{$image-path}partners/sig.png') 0 0 no-repeat;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/sig@2x.png');
					}

					@include mq($from: tablet) {
						width: 38px;
						height: 38px;
					}
					@include mq($from: desktop) {
						width: 45px;
						height: 45px; 
					}
				}
				&.logo-cleantech {
					width: 103px;
					height: 30px;
					background: transparent url('#{$image-path}partners/cleantech.png') 0 0 no-repeat;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/cleantech@2x.png');
					}

					@include mq($from: tablet) {
						width: 131px;
						height: 38px;
					}
					@include mq($from: desktop) {
						width: 155px;
						height: 45px;
					}
				}
				&.logo-duvoisin {
					width: 85px;
					height: 30px;
					background: transparent url('#{$image-path}partners/duvoisin.png') 0 0 no-repeat;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/duvoisin@2x.png');
					}

					@include mq($from: tablet) {
						width: 107px;
						height: 38px;
					}
					@include mq($from: desktop) {
						width: 130px;
						height: 46px;
					}
				}
				&.logo-connect {
					width: 76px;
					height: 30px;
					background: transparent url('#{$image-path}partners/connect.png') 0 0 no-repeat;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/connect@2x.png');
					}

					@include mq($from: tablet) {
						width: 90px;
						height: 36px;
					}
					@include mq($from: desktop) {
						width: 110px;
						height: 43px;
					}
				}
				&.logo-romande {
					height: 55px;
					width: 60px;
					background: transparent url('#{$image-path}partners/romande_logo.gif') 0 0 no-repeat;
					background-position: 0px -7px;
					background-size: 100% 100%;

					@include retina {
						background-image: url('#{$image-path}partners/romande_logo.gif');
					}

					@include mq($from: tablet) {
						width: 60px;
						background-position: 0px -7px;
						height: 55px;
					}
					@include mq($from: desktop) {
						width: 90px;
						height: 85px;
						background-position: 0px -10px;
						display: block;
					}
				}
				&.logo-sevj {
					height: 55px;
					width: 55px;
					background: transparent url('#{$image-path}partners/sevj_logo.gif') 0 0 no-repeat;
					background-size: 100% 100%;

					@include mq($from: tablet) {
						width: 55px;
						height: 55px;
					}
					@include mq($from: desktop) {
						width: 75px;
						height: 70px;
					}
				}
			}
		}
	}
}

.wrapper--footer {

	@include mq($from: mobileLandscape) {
		position: relative;
	}
	@include mq($from: tablet) {
		padding: 30px 0 60px 0;
	}
	@include mq(tablet, desktop) {
		max-width: 600px;
	}
	@include mq($from: desktop) {
		padding: 40px 0 50px 0;
	}
	@include mq($from: wide) {
		padding: 40px 0 60px 0;
	}
}

.footer--contact {
	@include clearfix;

	@include mq($from:mobileLandscape) {
		width: auto !important;
		float: left;
		padding-left: 38px;
	}
	@include mq($from: tablet) {
		padding-left: 50px;
	}
	@include mq($from: desktop) {
		padding-left: 69px;
	}
	@include mq($from: wide) {
		padding-left: 81px;
	}

	.contact-title {
		@include font-size(20);

		.no-touch & {
			-webkit-font-smoothing: antialiased;
		}

		@include mq($from: wide) {
			@include font-size(22);
			margin-bottom: 5px;
		}	
	}

	p {
		@include font-size(12);
		line-height: 1.4em;

		@include mq($from: tablet) {
			display: inline-block;
			padding-right: 20px;

			&:last-of-type {
				margin: 0;
			}
		}
		@include mq($from: wide) {
			line-height: 1.5em;
			padding-right: 28px;
		}

		span.upper {
			display: block;
			padding-top: 4px;
		}

		.mailto {

			.no-touch & {
				&:hover {
					color: $white;
					color: rgba($white, 0.8);
				}
			}
		}
	}
}

.dti-links {

	@include mq($from: mobileLandscape) {
		width: 40% !important;
		float: right;
		padding-top: 15px;
	}
	@include mq($from: tablet) {
		width: auto !important;
		padding-top: 20px;
	}
	@include mq($from: desktop) {
		padding-top: 25px;
	}

	li {
		margin: 0;
		margin: 0 0 0.25em 0;

		@include mq($from: tablet) {
			margin: 0 0 0.5em 0;
		}
		@include mq($from: desktop) {
			display: inline-block;
			margin-left: 70px;

			&:first-of-type {
				margin-left: 0;
			}
		}
		@include mq($from: wide) {
			display: inline-block;
			margin-left: 100px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&.advisors a {
			color: $color-advisor;

			&:before {
				background-color: $color-advisor;
			}

			.no-touch & {
				&:hover {
					color: lighten($color-advisor, 10%);

					&:before {
						background-color: lighten($color-advisor, 10%);
					}
				}
			}
		}
		&.communications a {
			color: $color-communication;

			&:before {
				background-color: $color-communication;
			}

			.no-touch & {
				&:hover {
					color: lighten($color-communication, 10%);

					&:before {
						background-color: lighten($color-communication, 10%);
					}
				}
			}
		}
		&.international a {
			color: $color-international;

			&:before {
				background-color: $color-international;
			}

			.no-touch & {
				&:hover {
					color: lighten($color-international, 10%);

					&:before {
						background-color: lighten($color-international, 10%);
					}
				}
			}
		}

		a {
			.no-touch & {
				-webkit-font-smoothing: antialiased;
			}

			&:before {
				top: 11px;
			}

			@include mq($from: desktop) {
				&:before {
					width: 20px;
					left: -30px;
				}
			}
			@include mq($from: wide) {
				@include font-size(17);

				&:before {
					width: 28px;
					left: -40px;
				}
			}
		}
	}
}

.footer--bottom {
	@include clearfix;
	margin-top: 5px;

	@include mq(mobileLandscape, tablet) {
		width: 40%;
		float: right;
		padding-left: 8px;
		bottom: 33px;
		position: absolute;
		right: 0;
	}
	@include mq($from: tablet) {
		float: left;
		padding-left: 37px;
	}
	@include mq($from: desktop) {
		position: absolute;
		right: 0;
		bottom: 65px;
	}
	@include mq($from: wide) {
		position: absolute;
		right: 0;
		bottom: 75px;
	}
}

.footer--social {
	line-height: 100%;

	@include mq($until: mobileLandscape) {
		display: inline-block;
		float: left;
	}
	@include mq($from: tablet) {
		margin: 0;
	}
	@include mq($from: desktop) {
		text-align: right;
	}

	li {
		display: inline-block;
		line-height: 2em;

		@include mq($from: mobileLandscape) {
			line-height: 1.2em;
		}

		a {
			@include font-size(24);

			transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(20);
			}

			.no-touch & {
				&:hover {
					color: $white;
					color: rgba($white, 0.8);

					animation-name: scale;
					animation-duration: 0.3s;
					animation-timing-function: linear;
					animation-iteration-count: 1;
				}
			}
		}
	}
}

.footer--mention {
	margin: 0;
	font-family: $font-light;
	@include font-size(11);

	@include mq($until: mobileLandscape) {
		line-height: 2.6em;
		vertical-align: bottom;
		float: right;
	}
	@include mq($from: mobileLandscape) {
		padding-left: 3px;
	}
	@include mq($from: tablet) {
		@include font-size(10);
		line-height: 1.2em;
	}
	@include mq($from: wide) {
		@include font-size(11);
		padding-right: 5px;
	}
}